import * as React from "react";

function Icon(props) {
  return (
    <>
      <style jsx>{`
        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
        .spinner {
          animation: rotate 0.5s infinite steps(12);
        }
      `}</style>
      <svg
        className="spinner"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M13 4a1 1 0 10-2 0h2zm-2 15a1 1 0 102 0h-2zm0-12a1 1 0 102 0h-2zm2 11a1 1 0 10-2 0h2zM11 4v3h2V4h-2zm0 14v1h2v-1h-2zM7.866 2.84a1 1 0 00-1.732 1l1.732-1zm6.768 15.722a1 1 0 101.732-1l-1.732 1zm-5-8.66a1 1 0 001.732-1l-1.732 1zm6.232 6.794a1 1 0 10-1.732 1l1.732-1zM6.134 3.84l3.5 6.062 1.732-1-3.5-6.062-1.732 1zm8 13.856l.5.866 1.732-1-.5-.866-1.732 1z"
          fill="#000"
        />
        <path
          d="M4.706 6.634a1 1 0 00-1 1.732l1-1.732zm12.856 9.732a1 1 0 101-1.732l-1 1.732zm-9.526-5.5a1 1 0 101-1.732l-1 1.732zm9.66 3.268a1 1 0 10-1 1.732l1-1.732zM3.706 8.366l4.33 2.5 1-1.732-4.33-2.5-1 1.732zm12.99 7.5l.866.5 1-1.732-.866-.5-1 1.732zM3.5 11a1 1 0 100 2v-2zM19 13a1 1 0 100-2v2zM7.5 13a1 1 0 100-2v2zM18 11a1 1 0 100 2v-2zM3.5 13h4v-2h-4v2zM18 13h1v-2h-1v2zM4.572 15.134a1 1 0 101 1.732l-1-1.732zm13.99-5.768a1 1 0 00-1-1.732l1 1.732zm-10.392 6a1 1 0 10-1-1.732l1 1.732zm8.526-7.232a1 1 0 101 1.732l-1-1.732zM5.572 16.866l2.598-1.5-1-1.732-2.598 1.5 1 1.732zm12.124-7l.866-.5-1-1.732-.866.5 1 1.732zM7.384 17.995a1 1 0 101.732 1l-1.732-1zm8.982-11.557a1 1 0 10-1.732-1l1.732 1zm-6.25 10.825a1 1 0 10-1.732-1l1.732 1zm4.018-10.96a1 1 0 101.732 1l-1.732-1zM9.116 18.996l1-1.732-1.732-1-1 1.732 1.732 1zm6.75-11.691l.5-.866-1.732-1-.5.866 1.732 1z"
          fill="#000"
        />
      </svg>
    </>
  );
}

export default Icon;
