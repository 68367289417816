import SubscribeForm from "../components/SubscribeForm";

const Footer = () => (
  <>
    <style jsx>{`
      .footer {
        border-top: 2px solid var(--border-color);
        padding: 96px 0;
      }
      .footer-container {
        display: grid;
        gap: 12px;
        margin: 0 auto;
        padding: 0 24px;
        width: 552px;
      }
      .footer-title {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
        text-align: center;
      }
      @media (max-width: 600px) {
        .footer-container {
          width: 360px;
        }
      }
      @media (max-width: 360px) {
        .footer-container {
          padding: 0 24px;
          width: 300px;
        }
      }
    `}</style>
    <footer className="footer">
      <div className="footer-container">
        <h2 className="footer-title">Get early access to ColonyDB</h2>
        <SubscribeForm />
      </div>
    </footer>
  </>
);

export default Footer;
