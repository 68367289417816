const Container = ({ children }) => (
  <>
    <style jsx>{`
      .container {
        display: grid;
        gap: var(--size-xxxxl);
      }
    `}</style>
    <div className="container">{children}</div>
  </>
);

export default Container;
