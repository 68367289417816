import { forwardRef } from "react";

const Input = forwardRef(
  (
    {
      id,
      name,
      type,
      placeholder,
      "aria-label": ariaLabel,
      required,
      disabled,
    },
    ref
  ) => (
    <>
      <style jsx>{`
        .input {
          --background-color: var(--color-ghost);
        }
        @media (prefers-color-scheme: dark) {
          .input {
            --background-color: var(--color-dune);
          }
        }
        .input {
          background: var(--background-color);
          border: none;
          border-radius: var(--radius-m);
          box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.15);
          font: var(--font-body);
          padding: var(--size-s) 0;
          text-indent: var(--size-s);
          transition: box-shadow 0.5s;
        }
        .input::placeholder {
          color: var(--muted-color);
          font: var(--font-italic);
        }
        .input:focus {
          box-shadow: inset 0 0 0 2px #ffb40a;
        }
      `}</style>
      <input
        className="input"
        ref={ref}
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        aria-label={ariaLabel}
        required={required}
        disabled={disabled}
      />
    </>
  )
);

export default Input;
