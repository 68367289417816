import Button from "../components/Button";
import Input from "../components/Input";
import Spinner from "../components/Spinner";
import { useState, useRef } from "react";

const SubscribeForm = () => {
  const [status, setStatus] = useState("ready");
  const formInput = useRef(null);
  const emailInput = useRef(null);
  return (
    <>
      <style jsx>{`
        .form {
          display: grid;
          gap: 6px;
          grid-template-columns: auto;
          grid-template-rows: 1fr 1fr;
        }
        .link {
          text-decoration: underline;
        }
        .link:hover {
          text-decoration: none;
        }
        @media (min-width: 504px) {
          .form {
            grid-template-columns: 1fr auto;
            gap: 12px;
          }
        }
      `}</style>
      {status === "error" ? (
        <span>
          Sorry, something went wrong. Try the{" "}
          <a
            className="link"
            href={
              process.env
                .NEXT_PUBLIC_CAMPAIGN_MONITOR_FALLBACK_SUBSCRIPTION_FORM
            }
          >
            alternative sign-up form
          </a>
          .
        </span>
      ) : status === "success" ? (
        <span>✅ You've been added to the waitlist.</span>
      ) : (
        <form
          ref={formInput}
          className="form"
          action="/api/subscribe"
          method="post"
          onSubmit={async (event) => {
            event.preventDefault();
            setStatus("submitting");
            const response = await fetch(formInput.current.action, {
              method: "post",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                email: emailInput.current.value,
              }),
            });
            if (response.ok) {
              if (
                process.env
                  .NEXT_PUBLIC_GOOGLE_ADS_JOIN_MAILING_LIST_CONVERSION_ID
              ) {
                window.gtag("event", "conversion", {
                  send_to:
                    process.env
                      .NEXT_PUBLIC_GOOGLE_ADS_JOIN_MAILING_LIST_CONVERSION_ID,
                });
              }
              setStatus("success");
            } else {
              setStatus("error");
            }
          }}
        >
          <Input
            ref={emailInput}
            name="email"
            type="email"
            placeholder="jane@example.com"
            aria-label="Email"
            required
            disabled={status === "submitting"}
          />
          {status === "submitting" ? (
            <Spinner />
          ) : (
            <Button type="submit">Get early access</Button>
          )}
        </form>
      )}
    </>
  );
};

export default SubscribeForm;
